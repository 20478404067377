import type { LocalRoutes } from './type';

export const ROUTE_SEGMENT: LocalRoutes = {
    account: 'cont',
    activate: 'activeaza',
    active: 'activ',
    ad: 'oferta',
    agencies: 'agentii',
    agencyCompanies: 'agentii',
    agents: 'profiluri-de-agenti',
    archived: 'arhivat',
    business: 'business',
    callRequest: 'call-request',
    category: '',
    clipboard: 'salvat',
    companies: 'companii',
    confirmPassword: 'confirmati-parola',
    contacts: 'lista-brokeri',
    developerCompanies: 'dezvoltatori',
    developers: 'dezvoltatori',
    domainSearch: 'rezultate',
    editAd: 'editeaza-anuntul',
    emailNotifications: 'email-notifications',
    emails: 'emails',
    evaluate: 'evaluati',
    fbLoginCallbackPage: 'social-login-redirect',
    finance: 'finance',
    history: 'istoric',
    investment: 'ansamblu',
    investments: 'investitii',
    invoiceData: 'detalii-factura',
    invoices: 'facturi',
    locationsMap: 'regiuni',
    login: 'login',
    manage: 'administreaza',
    moderated: 'moderat',
    mortgageBrowser: 'mortgage-browser',
    myaccount: 'contul-meu',
    newAd: 'anunt-nou',
    newInvestment: 'proiectnou',
    passwordChanged: 'parola-schimbata',
    payments: 'plati',
    preview: 'previzualizare',
    pricing: 'preturi',
    privateUsers: 'persoane-fizice',
    profile: 'profil',
    profileActivation: 'profile-activation',
    prolong: 'prelungeste',
    promote: 'promova',
    propertyEvaluation: 'evaluarea-proprietatii',
    register: '',
    registerAgent: '',
    registerBusiness: 'inregistrare-companiei',
    registerBusinessAgency: 'agentie-imobiliara',
    renting: 'inchiriere',
    reset: 'reset',
    restored: 'reabonat',
    savedAds: 'anunturi',
    savedSearches: 'cautari',
    schedulePromote: 'planifica-promovare',
    search: 'cautare',
    searchNotFound: 'cautare-negasita',
    selling: 'vanzare',
    settings: 'setari',
    sitemap: 'harta-site',
    sublisting: 'lista-anunt',
    subscription: 'abonament',
    success: 'succes',
    summary: 'sumar',
    thankYou: 'multumesc',
    unsubscribe: 'dezaboneaza',
    unsubscribed: 'dezabonat',
    updated: 'lacurent',
    waiting: 'asteptare',
    wallet: 'portofel',
    cityLanding: 'imobiliare',
    crm: 'crm',
    authorization: 'authorization',
    status: 'status',
};
