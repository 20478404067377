import { APPLE, DUST, ERROR, GENERICS, GREY, GREY_UPDATED, MEADOW, SEA } from '@lib/styles/partials/colors';
import type { NestedPartial } from '@lib/utils/type';

import { extendTheme } from './extendTheme';
import type { RawTheme, Theme } from './type';

const STORIA_THEME: NestedPartial<RawTheme> = {
    atoms: {
        alert: {
            icon: {
                default: {
                    color: SEA.x500,
                },
            },
        },
        button: {
            primary: {
                default: {
                    backgroundColor: SEA.x500,
                },
                hover: {
                    backgroundColor: SEA.x400,
                },
                focus: {
                    backgroundColor: SEA.x400,
                },
                active: {
                    backgroundColor: SEA.x600,
                },
            },
            action: {
                default: {
                    backgroundColor: APPLE.x500,
                },
                hover: {
                    backgroundColor: APPLE.x400,
                },
                focus: {
                    backgroundColor: APPLE.x400,
                },
                active: {
                    backgroundColor: APPLE.x600,
                },
            },
        },
        checkbox: {
            primary: {
                checked: {
                    base: {
                        borderColor: APPLE.x500,
                        backgroundColor: APPLE.x500,
                    },
                    hover: {
                        borderColor: APPLE.x400,
                        backgroundColor: APPLE.x400,
                    },
                },
                unchecked: {
                    hover: {
                        borderColor: APPLE.x400,
                    },
                },
            },
        },
        dropdown: {
            base: {
                filter: {
                    backgroundColor: APPLE.x100,
                    color: APPLE.x500,
                },
            },
            icon: {
                filter: {
                    color: APPLE.x500,
                },
            },
        },
        actionLink: {
            secondary: {
                default: { color: '#303030' },
                hover: { color: '#303030' },
                disabled: { color: GREY.x200 },
            },
        },
    },
    pages: {
        login: {
            login: {
                forgotPasswordLink: {
                    default: {
                        color: APPLE.x500,
                    },
                    hover: {
                        color: APPLE.x400,
                    },
                    focus: {
                        color: APPLE.x400,
                    },
                    active: {
                        color: APPLE.x600,
                    },
                },
                error: {
                    color: SEA.x500,
                },
            },
            resetPassword: {
                goBackLink: {
                    default: {
                        color: APPLE.x500,
                    },
                    hover: {
                        color: APPLE.x400,
                    },
                    focus: {
                        color: APPLE.x400,
                    },
                },
                error: {
                    color: SEA.x500,
                },
            },
            confirmPassword: {
                error: {
                    color: SEA.x500,
                },
            },
        },
        manage: {
            newInvestment: {
                thankYou: {
                    color: '#303030',
                    icon: {
                        color: SEA.x500,
                    },
                },
            },
        },
    },
    domains: {
        companies: {
            stickyActionButtons: {
                action: {
                    backgroundColor: SEA.x500,
                },
            },
        },
        shared: {
            toggle: {
                switch: {
                    checked: { backgroundColor: SEA.x500 },
                },
            },
            addItemButton: {
                default: {
                    backgroundColor: APPLE.x500,
                },
                hover: {
                    backgroundColor: APPLE.x400,
                },
                focus: {
                    backgroundColor: APPLE.x400,
                },
                active: {
                    backgroundColor: APPLE.x600,
                },
            },
            badge: {
                primary: {
                    backgroundColor: APPLE.x500,
                },
                success: {
                    backgroundColor: SEA.x500,
                },
                reversedColors: {
                    default: {
                        backgroundColor: DUST.x100,
                        color: DUST.x300,
                    },
                    primary: {
                        backgroundColor: SEA.x100,
                        color: SEA.x600,
                    },
                    success: {
                        backgroundColor: SEA.x100,
                        color: SEA.x600,
                    },
                },
            },
            featureIcon: {
                included: {
                    color: SEA.x500,
                },
            },
            textButton: {
                primary: {
                    color: SEA.x500,
                },
            },
            link: {
                primary: {
                    default: {
                        color: SEA.x500,
                    },
                    hover: {
                        color: SEA.x400,
                    },
                },
            },
            tabs: {
                tab: {
                    topLine: {
                        active: {
                            backgroundColor: SEA.x500,
                        },
                    },
                },
            },
            modalInfoItem: {
                checkIcon: {
                    color: SEA.x500,
                },
            },
            sortingDropdown: {
                buttonGroup: {
                    button: {
                        hover: {
                            backgroundColor: APPLE.x100,
                            color: APPLE.x500,
                        },
                    },
                },
            },
            specialOfferHeader: {
                discountHeader: {
                    backgroundColor: SEA.x100,
                    color: SEA.x600,
                },
            },
        },
        pricing: {
            bottomSeparator: {
                backgroundColor: GREY_UPDATED.x100,
            },
            link: {
                color: APPLE.x600,
            },
            feedbackButton: {
                backgroundColor: APPLE.x500,
            },
            tabsSwitcher: {
                icon: {
                    color: APPLE.x500,
                    backgroundColor: APPLE.x100,
                },
            },
            regularPricing: {
                rentRoomBanner: {
                    iconWrapper: {
                        fill: APPLE.x500,
                        backgroundColor: APPLE.x100,
                    },
                },
            },
        },
        search: {
            listing: {
                titleLink: {
                    color: SEA.x500,
                    hover: {
                        color: SEA.x400,
                    },
                },
            },
            listingItem: {
                base: {
                    openDays: {
                        backgroundColor: APPLE.x500,
                    },
                    actionIcon: {
                        primary: {
                            borderColor: APPLE.x500,
                            fill: APPLE.x500,
                        },
                        secondary: {
                            fill: SEA.x500,
                        },
                    },
                },
            },
            map: {
                pins: {
                    circleColor: {
                        basic: {
                            fill: GENERICS.white,
                            stroke: DUST.x300,
                            textColor: DUST.x300,
                        },
                        active: {
                            fill: APPLE.x500,
                            stroke: '#CF4028',
                            textColor: GENERICS.white,
                        },
                        visited: {
                            fill: '#D3D5D9',
                            stroke: GREY_UPDATED.x500,
                            textColor: GREY_UPDATED.x600,
                        },
                    },
                },
            },
            searchForm: {
                saveSearchResults: {
                    checkIcon: {
                        color: GENERICS.white,
                    },
                },
                rangeField: {
                    active: {
                        backgroundColor: APPLE.x100,
                    },
                },
                expandedSearchFormButton: {
                    indicator: {
                        backgroundColor: APPLE.x500,
                    },
                },
            },
            dropdownWithCheckboxes: {
                selected: {
                    backgroundColor: APPLE.x100,
                },
                closeIcon: {
                    color: APPLE.x500,
                },
            },
            breadcrumbs: {
                link: {
                    color: GENERICS.text,
                    textDecorationColor: GREY_UPDATED.x500,
                },
                label: {
                    color: GREY_UPDATED.x500,
                },
            },
            shortSubscriptionFlow: {
                forgotPasswordLink: {
                    default: {
                        color: SEA.x500,
                    },
                    hover: {
                        color: SEA.x500,
                    },
                },
            },
        },
        myAccount: {
            payments: {
                nextInvoiceTable: {
                    priceValue: {
                        color: APPLE.x500,
                    },
                },
                pastInvoiceTable: {
                    status: {
                        paid: {
                            color: SEA.x500,
                        },
                        unpaid: {
                            color: ERROR.x500,
                        },
                    },
                    labelCell: {
                        color: SEA.x500,
                    },
                },
                sharedTables: {
                    link: {
                        color: SEA.x500,
                    },
                },
            },
            paywall: {
                bundleSection: {
                    rentRoomBundle: {
                        iconWrapper: {
                            fill: APPLE.x500,
                            backgroundColor: APPLE.x100,
                        },
                    },
                },
            },
            ads: {
                adStatistics: {
                    exclusiveOffer: {
                        backgroundColor: SEA.x200,
                    },
                },
                adItem: {
                    developerAdsDisplay: {
                        color: SEA.x500,
                    },
                    moderationBar: {
                        backgroundColor: APPLE.x600,
                    },
                    promotions: {
                        itemActive: {
                            color: SEA.x500,
                        },
                    },
                    showStatisticsButton: {
                        color: APPLE.x500,
                    },
                },
                adsList: {
                    showFilters: {
                        color: APPLE.x500,
                        hover: {
                            color: APPLE.x400,
                        },
                    },
                },
                datePicker: {
                    day: {
                        default: {
                            backgroundColor: SEA.x100,
                        },
                        selected: {
                            backgroundColor: SEA.x500,
                        },
                    },
                    calendar: {
                        color: SEA.x500,
                    },
                },
            },
            shared: {
                accountStatisticsReport: {
                    downloadLink: {
                        icon: {
                            color: APPLE.x500,
                        },
                        label: {
                            color: APPLE.x500,
                        },
                    },
                },
                promoteReleaseModal: {
                    slideIndicator: {
                        borderColor: SEA.x500,
                        backgroundColor: GENERICS.transparent,
                        active: {
                            backgroundColor: SEA.x500,
                        },
                    },
                },
                accountBalanceAmount: {
                    amount: {
                        positive: {
                            color: MEADOW.x500,
                        },
                    },
                },
            },
            summary: {
                accountBalance: {
                    checkInvoicesButton: {
                        color: SEA.x500,
                    },
                },
                redReport: {
                    pieChart: {
                        chart: {
                            duplicates: {
                                fill: APPLE.x400,
                            },
                        },
                    },
                    reportStatistics: {
                        importantNumber: {
                            dark: {
                                color: GENERICS.text,
                            },
                            light: {
                                color: APPLE.x500,
                            },
                        },
                    },
                },
            },
        },
        ad: {
            adLocation: {
                color: SEA.x500,
            },
            adSubscribe: {
                default: {
                    borderColor: SEA.x500,
                    color: SEA.x500,
                },
                hover: {
                    borderColor: SEA.x500,
                    color: SEA.x500,
                    backgroundColor: `${SEA.x500}1a`,
                },
                focus: {
                    borderColor: SEA.x500,
                    color: SEA.x500,
                    backgroundColor: `${SEA.x500}1a`,
                },
            },
            adReportAdModal: {
                successStatus: {
                    color: APPLE.x500,
                },
            },
            phoneNumber: {
                showNumber: {
                    default: {
                        borderColor: SEA.x500,
                        color: SEA.x500,
                    },
                    active: {
                        borderColor: SEA.x500,
                        color: SEA.x500,
                        backgroundColor: SEA.x100,
                    },
                },
            },
            investmentUnitsList: {
                link: {
                    color: SEA.x500,
                },
            },
            contactForm: {
                mobileStickyFooter: {
                    backgroundColor: SEA.x500,
                },
            },
            adHeader: {
                location: {
                    color: SEA.x500,
                },
                developer: {
                    primary: {
                        color: SEA.x500,
                    },
                    secondary: {
                        color: SEA.x500,
                    },
                },
                investment: {
                    link: {
                        primary: {
                            color: SEA.x500,
                        },
                        secondary: {
                            color: SEA.x500,
                        },
                    },
                },
            },
            adAgencyBannerContent: {
                agencyName: {
                    color: SEA.x500,
                },
            },
            adUserAds: {
                color: SEA.x500,
            },
            adDescription: {
                color: GENERICS.text,
            },
            adRemoteService: {
                titleIcon: {
                    color: APPLE.x500,
                },
                tileButton: {
                    color: APPLE.x500,
                },
            },
            showAllAdsLink: {
                color: SEA.x500,
            },
        },
    },
};

export const THEME: Theme = extendTheme({ deprecated: STORIA_THEME });

// Keep it for backward compatibility - usage in tests
export const storiaro = THEME;
